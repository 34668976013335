<template>
    <div class="main">
        <div class="table">
            <el-table :data="gradesList" style="width: 100%">
                <el-table-column prop="historyYear" label="年份">
                </el-table-column>
                <el-table-column prop="realrecruittotal" label="实际招生数">
                </el-table-column>
                <el-table-column prop="address" label="第一志愿录取数">
                </el-table-column>
                <el-table-column prop="address" label="其他志愿录取数">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { queryCollegeScorePerson } from './../../../../api/college'

export default {
    name: "IndexPage",
    data: function () {
        return {
            gradesList: []
        }
    },
    props: ['collegeId', 'enrollBatchId'],
    mounted: function () {
        this.queryCollegePersonInfo();
    },
    methods: {
        queryCollegePersonInfo() {
            queryCollegeScorePerson({
                collegeid: this.collegeId,
                enrollbatchid: this.enrollBatchId,
            }).then(res => {
                if (res.rescode == 200) {
                    this.gradesList = res.gradesList;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    box-sizing: border-box;
    margin: 10px 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-select {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .table {
        width: 100%;

    }
}
</style>