<template>
    <div class="main-com">
        <div class="title">
            <div class="btn-back" @click="BackToQuery"><i class="el-icon-back"></i>返回</div>
            <div class="title-info">
                {{ id }} {{ name }} {{ courseRequire }} {{ enrollBatchName }}
            </div>
        </div>
        <el-menu @select="changeMenuItem" :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="score">高考录取分数</el-menu-item>
            <el-menu-item index="major">专业录取分数</el-menu-item>
            <el-menu-item index="person">高考录取人数</el-menu-item>
        </el-menu>

        <div class="content">
            <major-vue :collegeId="id" :enrollBatchId="enrollBatchId" v-if="activeIndex == 'major'"></major-vue>
            <score-vue :collegeId="id" :enrollBatchId="enrollBatchId" v-else-if="activeIndex == 'score'"></score-vue>
            <person-vue :collegeId="id" :enrollBatchId="enrollBatchId" v-else></person-vue>
        </div>
    </div>
</template>

<script>
import MajorVue from './Major.vue';
import ScoreVue from './Score.vue';
import PersonVue from './Person.vue';
export default {
    name: "IndexPage",
    data: function () {
        return {
            activeIndex: 'major',
            name: null,
            id: null,
            enrollBatchName: null,
            enrollBatchId: null,
        }
    },
    components: {
        MajorVue, PersonVue, ScoreVue
    },
    created: function () {
        this.name = this.$route.query.name;
        this.id = this.$route.query.id;
        this.enrollBatchName = this.$route.query.enrollBatchName;
        this.courseRequire = this.$route.query.courseRequire;
        this.enrollBatchId = this.$route.query.enrollBatchId;
    },
    methods: {
        changeMenuItem: function (index) {
            this.activeIndex = index;
        },
        BackToQuery: function () {
            this.$router.back();
        },

    }
}
</script>

<style lang="less" scoped>
.main-com{
    background: #cfd1cc;
}

.title {
    height: 50px;
    .btn-back {
        cursor: pointer;
        float: left;
        padding: 10px 0;
        margin: 3px 10px;
    }

    .title-info {
        font-size: 1.4em;
        font-weight: bold;
        padding: 10px 0;
        color: red;
    }
}

.content {
    padding-bottom: 40px;
}
</style>