<template>
    <div class="main">
        <el-table :data="gradesList" border style="width: 100%">
            <el-table-column prop="historyYear" label="年份" width="100">
            </el-table-column>
            <el-table-column prop="moveDocGrade" label="调档分" width="180">
            </el-table-column>
            <el-table-column prop="moveDocDiff" label="调档分本一批线差" width="180">
            </el-table-column>
            <el-table-column prop="moveDocRank" label="调档分位次">
            </el-table-column>
            <!-- <el-table-column prop="address" label="2022年高考成绩同位调档分">
            </el-table-column> -->
            <el-table-column prop="avgGrade" label="平均分">
            </el-table-column>
            <el-table-column prop="avgDiff" label="平均分本一批线差">
            </el-table-column>
            <el-table-column prop="avgRank" label="平均分位次">
            </el-table-column>
            <!-- <el-table-column prop="address" label="2022年高考成绩同位平均分">
            </el-table-column> -->
        </el-table>
    </div>
</template>

<script>
import { queryCollegeScorePerson } from './../../../../api/college'

export default {
    name: "IndexPage",
    data: function () {
        return {
            gradesList:[]
        }
    },
    props: ['collegeId', 'enrollBatchId'],
    mounted: function () {
        this.queryCollegeScoreInfo();
    },
    methods: {
        queryCollegeScoreInfo: function () {
            queryCollegeScorePerson({
                collegeid: this.collegeId,
                enrollbatchid: this.enrollBatchId,
            }).then(res => {
                if (res.rescode == 200) {
                    this.gradesList = res.gradesList;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    padding: 10%;
    box-sizing: border-box;
    padding-top: 20px;
}
</style>