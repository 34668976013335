<template>
    <div class="main">
        <div class="main-select">
            <el-form label-width="80px" :model="postInfo">
                <el-form-item label="选择年份">
                    <el-select v-model="postInfo.year" placeholder="请选择" @change="HandleYearChange">
                        <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="table">
            <div v-if="resInfo.majorCount == 0">
                <el-empty description="未查询到符合条件的专业"></el-empty>
            </div>
            <div v-else>
                <el-table :data="resInfo.majorList" style="width: 100%">
                    <el-table-column prop="majorname" label="专业名称" width="180">
                    </el-table-column>
                    <el-table-column prop="minGrade" label="实录分" width="180">
                    </el-table-column>
                    <el-table-column prop="minGradeDiff" label="实录分线差">
                    </el-table-column>
                    <el-table-column prop="minGradeRank" label="实录分位次">
                    </el-table-column>
                    <el-table-column prop="avgGrade" label="平均分">
                    </el-table-column>
                    <el-table-column prop="avgGradeDiff" label="平均分线差">
                    </el-table-column>
                    <el-table-column prop="avgGradeRank" label="平均分位次">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-plus" type="text"
                                @click="HandleContract(scope.$index, scope.row)">
                                对比</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[2, 10, 20]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="resInfo.majorCount">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import { queryCollegeMajor } from './../../../../api/college'

export default {
    name: "IndexPage",
    data: function () {
        return {
            yearList: ['2021', '2020', '2019', '2018'],
            postInfo: {
                year: '2021',
            },
            resInfo: {
                majorCount: 0,
                // majorList: [{
                //     majorname: '计算机',
                // }],
                majorList:null
            },
            currentPage: 1, // 当前第几页
            pageSize: 10,   // 当前页大小
        }
    },
    props: ['collegeId', 'enrollBatchId'],
    mounted: function () {
        this.resInfo.majorCount = this.resInfo.majorList.length;
        this.queryCollegeMajorInfo();
    },
    methods: {
        HandleYearChange: function (val) {
            this.postInfo.year = val;
            this.queryCollegeMajorInfo();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
            this.changePagePost();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.changePagePost();
        },
        changePagePost: function () {
            this.queryCollegeMajorInfo();
        },
        queryCollegeMajorInfo() {
            queryCollegeMajor({
                collegeid: this.collegeId,
                enrollbatchid: this.enrollBatchId,
                year: this.postInfo.year,
                pageNum: this.currentPage,
                pageSize: this.pageSize
            }).then(res => {
                if (res.rescode == 200) {
                    this.resInfo.majorCount = res.totalCount;
                    this.resInfo.majorList = res.majorList;
                }
            })
        },
        HandleContract: function(index,row){
            console.log(index,row);
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    box-sizing: border-box;
    margin: 10px 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-select {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .table {
        width: 100%;

    }
}
</style>